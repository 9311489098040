(function() {
	'use strict';
	var tooltipInit = function(){
		document.querySelectorAll(".pricebox [data-bs-toggle='tooltip']").forEach(function(tooltip){
			var bsnTip = new BSN.Tooltip(tooltip);
		});
	};

	// listen to pricebox change and init the tooltip on #mac buy buttons
	window.addEventListener("load", tooltipInit);
	window.addEventListener("pricebox-updated", tooltipInit);

})();
